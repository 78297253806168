import React, {useState} from 'react'

import {toast} from 'react-toastify'
import {Container} from './styles'
import getInstance from '../../helpers/axios'


const LiveRecon = () => {

  const [liveRecon, setLiveRecon] = useState('')

  const filterForOnlyRequiredRisk = async () => {
    const parsed = JSON.parse(liveRecon)
    const newState = [ ]
    for (let account of parsed) {
      if (account.requestType === "REQUIRED_NO_BALANCE") {
        newState.push({ ...account, fractionTillMaintenace: account.marginFraction - account.maintenanceMarginFraction  } )
      }
    }
    
    setLiveRecon(JSON.stringify(newState.sort( a=> a.fractionTillMaintenace)))
  }

  const postLiveReconRequest = async (url, data) => {
    try {
      const result = await getInstance().post(`/api/liverecon/${url}`, data)
      setLiveRecon(JSON.stringify(result.data))
    } catch (e) {
      toast.error(`Live recon request failed - ${e}`)
    }
  }

  const putLiveReconRequest = async (url, data) => {
    try {
      const result = await getInstance().put(`/api/liverecon/${url}`, data)
      setLiveRecon(JSON.stringify(result.data))
    } catch (e) {
      toast.error(`Live recon request failed - ${e}`)
    }
  }

  const getLiveReconRequest = async (url) => {
    try {
      const result = await getInstance().get(`/api/liverecon/${url}`)
      setLiveRecon(JSON.stringify(result.data))
    } catch (e) {
      toast.error(`Live recon request failed - ${e}`)
    }
  }

  const putApplicationRequest = async (url, data) => {
    try {
      const result = await getInstance().put(`/api/settings/${url}`, data)
      setLiveRecon(JSON.stringify(result.data))
    } catch (e) {
      toast.error(`Application request failed - ${e}`)
    }
  }

  const requestRunPnl = async () => {
    const pairId = prompt('Pair Id');
    if (!pairId) { alert('cancelled'); return }
    putLiveReconRequest('%2Ffutures%2Frun-pnl', { pairId })
  }

  const requestRunFunding = async () => {
    const pairId = prompt('Pair Id');
    if (!pairId) { alert('cancelled'); return }
    putLiveReconRequest('%2Ffutures%2Frun-funding', { pairId })
  }

  return (<Container>
    <div>
      <ul>
        <li>
          <div>
            <button onClick={() => { getLiveReconRequest('%2Findex-prices') }}>Show Index Prices</button>
            <button onClick={() => {
              const correlationId = prompt('Correlation id');
              if (!correlationId) { alert('cancelled'); return }
              const symbol = prompt('Symbol');
              if (!symbol) { alert('cancelled'); return }
              const price = prompt('Price');
              if (!price) { alert('cancelled'); return }
              const sourceCreatedAt = prompt('SourceCreatedAt Epoch Milli', Date.now());
              if (!sourceCreatedAt) { alert('cancelled'); return }

              putLiveReconRequest('%2Findex-prices', { correlationId, symbol, price, sourceCreatedAt })
          }}>Set index prices</button>
            <button onClick={() => { getLiveReconRequest('%2Fsync-stats') }}>Sync stats</button>
            <button onClick={requestRunPnl}>Request Pnl Run</button>
            <button onClick={requestRunFunding}>Request Funding Run</button>
            <button onClick={() => { getLiveReconRequest('%2Ffutures%2Fnett-positions') }}>NettPositions</button>
            <button onClick={() => {
              const accountId = prompt('accountId','-1');
              if (!accountId) { alert('cancelled'); return; }
              const settlementCurrencySymbol = prompt('settlementCurrencySymbol','-1');
              if (!settlementCurrencySymbol) { alert('cancelled'); return; }
              const pairSymbol = prompt('pairSymbol','-1');
              if (!pairSymbol) { alert('cancelled'); return; }
              const dryRun = prompt('dry run', 'true');
              if (!dryRun) { alert('cancelled'); return; }

              putLiveReconRequest('%2Fcollateral-conversion-run', { settlementCurrencySymbol: settlementCurrencySymbol && settlementCurrencySymbol !== '-1' ? settlementCurrencySymbol : null, pairSymbol: pairSymbol && pairSymbol !== '-1' ? pairSymbol : null, accountId: accountId != '-1' ? Number(accountId): null,  dryRun })  }}>Convert Collateral</button>
            <button onClick={() => {
              const symbol = prompt('Currency');
              getLiveReconRequest(`%2Fborrows-info%2F${symbol}`) }}>Borrows info (network tab)</button>

              <br/>
          </div>
        </li>
        <li>
          <div>
            <button onClick={() => {
              const accountId = window.prompt('Please provide account id')
              postLiveReconRequest('%2Fmismatched-exchange-account-balance', {accountId})
            }}>Compare mismatched transaction balances for an account
            </button>
          </div>
        </li>
        <li><br/></li>
        <li>
          <div>
            <button onClick={() => {
              const sampleSize = window.prompt('Please provide sample size')
              postLiveReconRequest('%2Fmismatched-exchange-account-balances', {sampleSize})
            }}>Compare mismatched transaction balances for a sample of accounts
            </button>
          </div>
        </li>
        <li><br/></li>
        <li>
          <div>
            <button onClick={() => {
              const accountId = window.prompt('Please provide account id')
              postLiveReconRequest('%2Fbalances', {accountId})
            }}>Show exchange balance and live recon balance for an account
            </button>
          </div>
        </li>
        <div>---</div>
        <li>
          <div>
            <button onClick={() => {
              const accountId = window.prompt('Please provide account id')
              getLiveReconRequest(`%2Fbalance%2F${accountId}`)
            }}>Show and print balance for an account
            </button>
          </div>
        </li>
        <li>
          <div>
            <button onClick={() => {
              const precision = window.prompt('Please provide precision', '32')
              const scale = window.prompt('Please provide scale', '32')
              getLiveReconRequest(`%2Fflagbalances%3Fprecision%3D${precision}%26scale%3D${scale}`)
            }}>Flag Balances with precision and scale
            </button>
          </div>
        </li>
        <div>---</div>
        <div>Application Requests:</div>
        <div>---</div>
        <button onClick={() => { 
            const ok = prompt("Are you sure", "OK")
            if (!ok || ok != "OK") { alert('cancelled'); return }
            putApplicationRequest('run-auction', null)
         }}>Requests run auction on application</button>
         <div>---</div>
        <li><br/><br/><br/></li>
        <button onClick={() => { setLiveRecon('') }}>Clear data</button>
        <div>{liveRecon.toString()}</div>
      </ul>
    </div>
  </Container>)
}

export default LiveRecon
