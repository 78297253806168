import React, {useState} from 'react'

import {toast} from 'react-toastify'
import {Container} from './styles'
import getInstance from '../../helpers/axios'

const Risk = () => {

  const [data, set] = useState('')

  const filterForOnlyRequiredRisk = async () => {
    const parsed = JSON.parse(data)
    const newState = [ ]
    for (let account of parsed) {
      if (account.requestType === "REQUIRED_NO_BALANCE") {
        newState.push({ ...account, fractionTillMaintenace: account.marginFraction - account.maintenanceMarginFraction  } )
      }
    }
    
    set(JSON.stringify(newState.sort( a=> a.fractionTillMaintenace)))
  }

  const postLiveReconRequest = async (url, data) => {
    try {
      const result = await getInstance().post(`/api/risk/${url}`, data)
      set(JSON.stringify(result.data))
    } catch (e) {
      toast.error(`Live recon request failed - ${e}`)
    }
  }

  const putRequest = async (url, data) => {
    try {
      const result = await getInstance().put(`/api/risk/${url}`, data)
      set(JSON.stringify(result.data))
    } catch (e) {
      toast.error(`Live recon request failed - ${e}`)
    }
  }

  const getRequest = async (url) => {
    try {
      const result = await getInstance().get(`/api/risk/${url}`)
      set(JSON.stringify(result.data))
    } catch (e) {
      toast.error(`Live recon request failed - ${e}`)
    }
  }

  return (<Container>
    <div>
      <ul>
        <li>
          <div>
            <button onClick={() => {
              getRequest(`%2Frisk%2Flatest-collateral-outcomes`) }}>Latest Collateral adjustments</button>

            <button onClick={() => {
              getRequest(`%2Frisk%2Flast-run-info`) }}>Last Run Info</button>

            <button onClick={() => {
              getRequest(`%2Frisk%2Fcollateral-preference`) }}>Show collateral preferences</button>

            <button onClick={() => {
              const shortName = prompt('Short name');
              const priority = prompt('Priority');
              const overrideValidation = prompt('overrideValidation', 'false');
              if (!shortName || !priority) { alert('cancelled'); return }

              putRequest('%2Frisk%2Fcollateral-preference', { shortName, priority, overrideValidation })
          }}>Set collateral preference</button>

            <button onClick={() => { filterForOnlyRequiredRisk(data, set) }}>Filter for only required Risk</button>
              <br/>

          </div>
        </li>
        <button onClick={() => { set('') }}>Clear data</button>
        <div>{data.toString()}</div>
      </ul>
    </div>
  </Container>)
}

export default Risk
