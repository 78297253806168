import React, {useEffect, useState} from 'react'
import getInstance from 'helpers/axios'
import Select from 'react-select'
import {Container, Table, TableBody, TableCell, TableHeader, TableRow,} from './styles'
import {toast} from "react-toastify";

const CustomLeverage = () => {
  const [leverageTiers, setLeverageTiers] = useState([])
  const [customAccountLeverage, setCustomAccountLeverage] = useState()
  const [currencyPair, setCurrencyPair] = useState()
  const [optionsValue, setOptionsValues] = useState()
  const [customAccountToView, setCustomAccountToView] = useState()
  const [requestedData, setRequestedData] = useState(false)

  const countDecimals = function (value) {
    if (Math.floor(value) === value) return 0
    return value.toString().split('.')[1].length || 0
  }

  const getCurrencyPairs = async () => {
    try {
      const res = await getInstance().get('/api/currency/pairs')
      const {data} = res
      const availableCurrencyPairs = data
        .filter(currencyPair => currencyPair.currencyPairType === 'FUTURE' && currencyPair.active)
        .map(currencyPair => ({
          value: currencyPair.symbol,
          label: currencyPair.symbol,
        }))
      setOptionsValues(availableCurrencyPairs)
    } catch (error) {
      return error
    }
  }

  const getLeverageTiers = async (currencyPair, accountLeverages) => {
    try {
      const res = await getInstance().get(`/api/custom-leverage-tiers/${currencyPair}`)
      const {data} = res
      const displayData = data.map(it => ({
        ...it,
        usersInTier: accountLeverages[it.leverageMultiple] ? accountLeverages[it.leverageMultiple].length : 0
      }))
      setLeverageTiers(displayData)
      console.log(displayData)
    } catch (error) {
      return error
    }
  }

  const getAccountCustomLeverage = async (currencyPair) => {
    try {
      const res = await getInstance().get(`/api/account-custom-leverage/${currencyPair}`)
      const {data} = res
      const leverageMap = {}
      data.forEach(it => {
        let existing = leverageMap[it.leverageMultiple]
        if (existing == null) {
          existing = []
        }
        existing.push(it)
        leverageMap[it.leverageMultiple] = existing
      })
      // console.log(leverageMap)
      setCustomAccountLeverage(leverageMap)
      return leverageMap
    } catch (error) {
      return {}
    }
  }

  const getCustomLeverageInfo = async (currencyPair, clearLeverageTiersState) => {
    if (currencyPair == null) return
    setCustomAccountToView(null)
    setCustomAccountLeverage(null)

    if (clearLeverageTiersState === true) setLeverageTiers([])

    let accountLeverages = await getAccountCustomLeverage(currencyPair)
    await getLeverageTiers(currencyPair, accountLeverages)
    setRequestedData(true)
  }

  const addLeverageTier = async (currencyPair) => {
    if (currencyPair == null) return

    const jsonData = prompt('JSON data for this tier. Format: \n\n {"leverageMultiple": "5", "initialMarginFraction": "0.2", "maintenanceMarginFraction": "0.01", "autoCloseMarginFraction": "0.03", "riskLimit": "15000000"}')
    const dataToSubmit = JSON.parse(jsonData)

    const data = {
      leverageMultiple: dataToSubmit.leverageMultiple,
      initialMarginFraction: dataToSubmit.initialMarginFraction,
      maintenanceMarginFraction: dataToSubmit.maintenanceMarginFraction,
      autoCloseMarginFraction: dataToSubmit.autoCloseMarginFraction,
      riskLimit: dataToSubmit.riskLimit,
    }

    const ok = prompt(`${JSON.stringify(data)} \n\n Submit data?`, 'OK')
    if (!ok || ok !== 'OK') {
      alert('Cancelled');
      return
    }

    try {
      const res = await getInstance().post(`/api/custom-leverage-tiers/${currencyPair}`, data)
      toast.success(`Success please fetch again if data doesn't show up: ${res.data}`)
      getCustomLeverageInfo(currencyPair)
    } catch (e) {
      toast.error(`Post fail ${e}`, {
        position: 'top-right',
        autoClose: 5000,
      })
    }
  }

  const modifyLeverageTier = async (currencyPair, existingTier) => {
    if (currencyPair == null) return

    const initialMarginFraction = prompt('Initial margin fraction for this tier. Fractional so 10% = 0.1', existingTier.initialMarginFraction)
    if (initialMarginFraction == null || countDecimals(initialMarginFraction) < 1 || countDecimals(initialMarginFraction) > 4) {
      alert(`Cancelled because value ${initialMarginFraction} has too many decimal places ${countDecimals(initialMarginFraction)}`)
      return
    }

    const maintenanceMarginFraction = prompt('Maintenance margin fraction for this tier. Fractional so 10% = 0.1', existingTier.maintenanceMarginFraction)
    if (maintenanceMarginFraction == null || countDecimals(maintenanceMarginFraction) < 1 || countDecimals(maintenanceMarginFraction) > 4) {
      alert(`Cancelled because value ${maintenanceMarginFraction} has too many decimal places ${countDecimals(maintenanceMarginFraction)}`)
      return
    }

    const autoCloseMarginFraction = prompt('Auto close margin fraction for this tier. Fractional so 10% = 0.1', existingTier.autoCloseMarginFraction)
    if (autoCloseMarginFraction == null || countDecimals(autoCloseMarginFraction) < 1 || countDecimals(autoCloseMarginFraction) > 4) {
      alert(`Cancelled because value ${autoCloseMarginFraction} has too many decimal places ${countDecimals(autoCloseMarginFraction)}`)
      return
    }

    const riskLimit = prompt('Risk limit in quote for this pair', existingTier.riskLimit)
    if (riskLimit == null || riskLimit <= 0) {
      alert(`Cancelled due to incorrect value: ${riskLimit}`)
      return
    }

    const data = {
      leverageMultiple: existingTier.leverageMultiple,
      initialMarginFraction,
      maintenanceMarginFraction,
      autoCloseMarginFraction,
      riskLimit,
    }

    const existing = {
      leverageMultiple: existingTier.leverageMultiple,
      initialMarginFraction: existingTier.initialMarginFraction,
      maintenanceMarginFraction: existingTier.maintenanceMarginFraction,
      autoCloseMarginFraction: existingTier.autoCloseMarginFraction,
      riskLimit: existingTier.riskLimit,
    }

    const ok = prompt(`Existing: \n ${JSON.stringify(existing)} \n\n New: \n ${JSON.stringify(data)} \n\n Submit data?`, 'OK')
    if (!ok || ok !== 'OK') {
      alert('Cancelled');
      return
    }

    try {
      const res = await getInstance().post(`/api/custom-leverage-tiers/${currencyPair}`, data)
      toast.success(`Success please fetch again if data doesn't show up: ${res.data}`)
      getCustomLeverageInfo(currencyPair)
    } catch (e) {
      toast.error(`Post fail ${e}`, {
        position: 'top-right',
        autoClose: 5000,
      })
    }
  }

  const setDefaultTier = async (currencyPair, existingTiers) => {
    const currentDefault = existingTiers.find(it => it.isDefault)
    const currentDefaultLm = currentDefault ? currentDefault.leverageMultiple : null

    const leverageMultiple = prompt('New default leverage multiple', currentDefaultLm)
    if (leverageMultiple == null || leverageMultiple <= 1 || isNaN(leverageMultiple)) {
      alert(`Cancelled due to incorrect value: ${leverageMultiple}`)
      return
    }

    if (currentDefaultLm != null && existingTiers.find(it => it.leverageMultiple === leverageMultiple) == null) {
      alert(`Cancelled because ${leverageMultiple} is not a leverage multiple used by a tier`)
      return
    }

    const ok = prompt(`Will set default leverage multiple for ${currencyPair} to ${leverageMultiple} from ${currentDefaultLm}`, 'OK')
    if (!ok || ok !== 'OK') {
      alert('Cancelled');
      return
    }

    try {
      const res = await getInstance().post(`/api/default-leverage-tiers/${currencyPair}`, {leverageMultiple})
      toast.success(`Success please fetch again if data doesn't show up: ${res.data}`)
      getCustomLeverageInfo(currencyPair)
    } catch (e) {
      toast.error(`Post fail ${e}`, {
        position: 'top-right',
        autoClose: 5000,
      })
    }
  }

  const removeLeverageTier = async (currencyPair, leverageMultiple) => {
    try {
      const ok = prompt(`Are you sure you want to delete tier ${leverageMultiple}`, 'OK')
      if (!ok || ok !== 'OK') {
        alert('Cancelled');
        return
      }
      const res = await getInstance().post(`/api/delete-custom-leverage-tiers/${currencyPair}`, {leverageMultiple})
      toast.success(`Success please fetch again if data doesn't show up: ${res.data}`)
      getCustomLeverageInfo(currencyPair)
    } catch (e) {
      toast.error(`Post fail ${e}`, {
        position: 'top-right',
        autoClose: 5000,
      })
    }
  }

  const setUserLeverage = async (currencyPair) => {
    const accountId = prompt('Account id')
    if (accountId == null || accountId <= 1 || isNaN(accountId)) {
      alert(`Cancelled due to incorrect value: ${accountId}`)
      return
    }

    const leverageMultiple = prompt('Leverage multiple')
    if (leverageMultiple == null || leverageMultiple <= 1 || isNaN(leverageMultiple)) {
      alert(`Cancelled due to incorrect value: ${leverageMultiple}`)
      return
    }

    const ok = prompt(`Set accountId:${accountId} leverageMultiple:${leverageMultiple}?`, 'OK')
    if (!ok || ok !== 'OK') {
      alert('Cancelled');
      return
    }

    try {
      const data = {
        accountId,
        leverageMultiple,
        moveToDefault: false,
      }
      const res = await getInstance().post(`/api/account-custom-leverage/${currencyPair}`, data)
      toast.success(`Success please fetch again: ${res.data}`)
    } catch (e) {
      toast.error(`Post fail ${e}`, {
        position: 'top-right',
        autoClose: 5000,
      })
    }
  }

  const setUserToDefaultLeverage = async (currencyPair, accountId) => {
    try {
      const data = {
        accountId,
        leverageMultiple: -1,
        moveToDefault: true,
      }
      const res = await getInstance().post(`/api/account-custom-leverage/${currencyPair}`, data)
      toast.success(`Success please fetch again: ${res.data}`)
    } catch (e) {
      toast.error(`Post fail ${e}`, {
        position: 'top-right',
        autoClose: 5000,
      })
    }
  }

  useEffect(() => {
    getCurrencyPairs()
  }, [])

  return (
    <Container>
      <div>
        <h3>Leverage Tiers</h3>
        <Select options={optionsValue} isDisabled={optionsValue == null} placeholder={'Select currency pair'} onChange={(e) => {
          setCurrencyPair(e.value)
          getCustomLeverageInfo(e.value, true)
        }}/>
        <div>&nbsp;</div>
        <button disabled={currencyPair == null} onClick={() => getCustomLeverageInfo(currencyPair)}>Refresh Info
        </button>
      </div>
      <div>&nbsp;</div>
      <div>
        <Table>
          <TableHeader>
            <TableCell flex={0.2}> Leverage Multiple </TableCell>
            <TableCell flex={0.2}> Initial </TableCell>
            <TableCell flex={0.2}> Maintenance </TableCell>
            <TableCell flex={0.2}> AutoClose </TableCell>
            <TableCell flex={0.2}> RiskLimit </TableCell>
            <TableCell flex={0.2}> Default </TableCell>
            <TableCell flex={0.2}> Users In Tier </TableCell>
            <TableCell flex={0.2}> Actions </TableCell>
          </TableHeader>
          <TableBody>
            {leverageTiers &&
              leverageTiers.map(p => (
                <TableRow>
                  <TableCell flex={0.2}>{p.leverageMultiple}</TableCell>
                  <TableCell flex={0.2}>{p.initialMarginFraction}</TableCell>
                  <TableCell flex={0.2}>{p.maintenanceMarginFraction}</TableCell>
                  <TableCell flex={0.2}>{p.autoCloseMarginFraction}</TableCell>
                  <TableCell flex={0.2}>{p.riskLimit}</TableCell>
                  <TableCell flex={0.2}>{p.isDefault === true ? 'True' : 'False'}</TableCell>
                  <TableCell flex={0.2}>{p.usersInTier} {' '}
                    <button onClick={() => {
                      setCustomAccountToView(p.leverageMultiple)
                    }}>View
                    </button>
                  </TableCell>
                  <TableCell flex={0.2}>
                    <button onClick={() => {
                      modifyLeverageTier(currencyPair, p)
                    }}>Modify
                    </button>
                    <button onClick={() => {
                      removeLeverageTier(currencyPair, p.leverageMultiple)
                    }}>Delete
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            {
              <TableRow>
                <TableCell flex={0.2}>
                  <button disabled={currencyPair == null} onClick={() => addLeverageTier(currencyPair)}>Add Leverage Tier (JSON)
                  </button>
                </TableCell>
                <TableCell flex={0.2}>
                  <button disabled={currencyPair == null} onClick={() => setDefaultTier(currencyPair, leverageTiers)}>Set default tier
                  </button>
                </TableCell>
                <TableCell flex={0.2}>
                  <button disabled={currencyPair == null} onClick={() => setUserLeverage(currencyPair)}>Set user leverage
                  </button>
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </div>
      <div>&nbsp;</div>
      <div>{(customAccountToView && customAccountLeverage && customAccountLeverage[customAccountToView]) ? customAccountLeverage[customAccountToView].map(it => (
        <TableRow>
          <TableCell flex={0.5}>{JSON.stringify(it)}</TableCell>
          <TableCell flex={0.2}>
            <button onClick={() => setUserToDefaultLeverage(currencyPair, it.accountId)}>Set To Default
            </button>
          </TableCell>
        </TableRow>
      )) : ''}</div>
      <div>&nbsp;</div>
    </Container>
  )
}

export default CustomLeverage
